<!-- eslint-disable vue/no-parsing-error -->
<script>
import { layoutComputed } from "@/state/helpers";
import mushroom from "cem-primary-api";
import noneIconMenu from "../assets/icon/none-icon-menu.svg";
import MethodService from "../service/MethodService";
import toastr from "toastr";
import { langWeb } from "../stores/lang";
import LanguageService from "../service/LanguageService";
export default {
  components: {},
  data() {
    return {
      loadingService: true,
      settings: {
        minScrollbarLength: 60,
      },
      roles: "",
      listMenu: [],
      probeData: {
        id: "",
        link: "",
      },
      probeId: localStorage.getItem("probeId") ?? "",
      lang_web: langWeb(),
    };
  },
  computed: {
    ...layoutComputed,
    layoutType: {
      get() {
        return this.$store ? this.$store.state.layout.layoutType : {} || {};
      },
    },
  },

  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
    "lang_web.showLang": {
      handler: "onChangeLang",
      immediate: true,
      deep: true,
    },
  },
  created() {},

  mounted() {
    this.getMenu();

    if (this.probeId) {
      // đã sang probe
      this.getProbeById(this.probeId);
    } else {
      this.get_data_probe();
    }

    const response = JSON.parse(localStorage.getItem("userInfo"));
    this.roles = response.result.roles;

    if (document.querySelectorAll(".navbar-nav .collapse")) {
      let collapses = document.querySelectorAll(".navbar-nav .collapse");
      collapses.forEach((collapse) => {
        // Hide sibling collapses on `show.bs.collapse`
        collapse.addEventListener("show.bs.collapse", (e) => {
          e.stopPropagation();
          let closestCollapse = collapse.parentElement.closest(".collapse");
          if (closestCollapse) {
            let siblingCollapses =
              closestCollapse.querySelectorAll(".collapse");
            siblingCollapses.forEach((siblingCollapse) => {
              if (siblingCollapse.classList.contains("show")) {
                let aChild = siblingCollapse.parentNode.firstChild;
                if (aChild.hasAttribute("aria-expanded")) {
                  aChild.setAttribute("aria-expanded", "false");
                }
                siblingCollapse.classList.remove("show");
              }
            });
          } else {
            let getSiblings = (elem) => {
              // Setup siblings array and get the first sibling
              let siblings = [];
              let sibling = elem.parentNode.firstChild;
              // Loop through each sibling and push to the array
              while (sibling) {
                if (sibling.nodeType === 1 && sibling !== elem) {
                  siblings.push(sibling);
                }
                sibling = sibling.nextSibling;
              }
              return siblings;
            };
            let siblings = getSiblings(collapse.parentElement);
            siblings.forEach((item) => {
              if (item.childNodes.length > 2)
                item.firstElementChild.setAttribute("aria-expanded", "false");
              let ids = item.querySelectorAll("*[id]");
              ids.forEach((item1) => {
                let aChild = item1.parentNode.firstChild;
                if (aChild?.hasAttribute("aria-expanded")) {
                  aChild?.setAttribute("aria-expanded", "false");
                }
                item1.classList.remove("show");
                if (item1.childNodes.length > 2) {
                  let val = item1.querySelectorAll("ul li a");

                  val.forEach((subitem) => {
                    if (subitem.hasAttribute("aria-expanded"))
                      subitem.setAttribute("aria-expanded", "false");
                  });
                }
              });
            });
          }
        });

        // Hide nested collapses on `hide.bs.collapse`
        collapse.addEventListener("hide.bs.collapse", (e) => {
          e.stopPropagation();
          let childCollapses = collapse.querySelectorAll(".collapse");
          childCollapses.forEach((childCollapse) => {
            let childCollapseInstance = childCollapse;
            childCollapseInstance.hide();
          });
        });
      });
    }
  },

  methods: {
    // lấy data của probe
    async get_data_probe() {
      try {
        const response = await mushroom.probe.get_probesAsync({
          body: {},
        });

        if (response.result) {
          const tokenAccount = window.localStorage.getItem(
            "mushroom.tokens" + "[" + mushroom.$using() + "]"
          );
          const tokenFragment = MethodService.getTokenAccount(tokenAccount);

          let url = "";
          let newUrl = "";
          let hashValue = "";
          if (response.result?.length > 0) {
            const probeNew = response.result[0];
            if (probeNew.url.includes("#")) {
              newUrl = probeNew.url.substring(0, probeNew.url.indexOf("#"));
              hashValue = probeNew.url.substring(probeNew.url.indexOf("#") + 1);
              if (probeNew.url.includes("?")) {
                newUrl = newUrl + "&" + tokenFragment;
              } else newUrl = newUrl + "?" + tokenFragment;

              if (hashValue) {
                newUrl = newUrl + "#" + hashValue;
              }
              probeNew.url =
                newUrl +
                "&pid=" +
                probeNew.id +
                "&urlPrimary=" +
                encodeURIComponent(location.origin) +
                "&nameProbe" +
                probeNew.columns.name +
                "&type_login=" +
                localStorage.getItem("type_login") +
                "&lang=" +
                (localStorage.getItem("language") ??
                  localStorage.getItem("language_default"));
            } else {
              if (probeNew.url.includes("?")) {
                probeNew.url =
                  probeNew.url +
                  "&" +
                  tokenFragment +
                  "&pid=" +
                  probeNew.id +
                  "&urlPrimary=" +
                  encodeURIComponent(location.origin) +
                  "&nameProbe" +
                  probeNew.columns.name +
                  "&type_login=" +
                  localStorage.getItem("type_login") +
                  "&lang=" +
                  (localStorage.getItem("language") ??
                    localStorage.getItem("language_default"));
              } else
                probeNew.url =
                  probeNew.url +
                  "?" +
                  tokenFragment +
                  "&pid=" +
                  probeNew.id +
                  "&urlPrimary=" +
                  encodeURIComponent(location.origin) +
                  "&nameProbe=" +
                  probeNew.columns.name +
                  "&type_login=" +
                  localStorage.getItem("type_login") +
                  "&lang=" +
                  (localStorage.getItem("language") ??
                    localStorage.getItem("language_default"));
            }
            url = probeNew.url;
            this.probeData = {
              id: probeNew.id,
              link: url,
            };
          }
        } else {
          this.probeData = {
            id: "",
            link: "",
          };
        }
      } catch (e) {
        console.error("Có lỗi: %o", e);
      }
    },

    // lấy data probe theo id
    async getProbeById(id) {
      try {
        const response = await mushroom.probe.findByIdAsync({
          id: id, // required
        });

        if (response.result) {
          const tokenAccount = window.localStorage.getItem(
            "mushroom.tokens" + "[" + mushroom.$using() + "]"
          );
          const tokenFragment = MethodService.getTokenAccount(tokenAccount);

          let url = "";
          let newUrl = "";
          let hashValue = "";
          const probeNew = response.result;
          if (probeNew.url.includes("#")) {
            newUrl = probeNew.url.substring(0, probeNew.url.indexOf("#"));
            hashValue = probeNew.url.substring(probeNew.url.indexOf("#") + 1);
            if (probeNew.url.includes("?")) {
              newUrl = newUrl + "&" + tokenFragment;
            } else newUrl = newUrl + "?" + tokenFragment;

            if (hashValue) {
              newUrl = newUrl + "#" + hashValue;
            }
            probeNew.url =
              newUrl +
              "&pid=" +
              probeNew.id +
              "&urlPrimary=" +
              encodeURIComponent(location.origin) +
              "&nameProbe" +
              probeNew.columns.name +
              "&type_login=" +
              localStorage.getItem("type_login") +
              "&lang=" +
              (localStorage.getItem("language") ??
                localStorage.getItem("language_default"));
          } else {
            if (probeNew.url.includes("?")) {
              probeNew.url =
                probeNew.url +
                "&" +
                tokenFragment +
                "&pid=" +
                probeNew.id +
                "&urlPrimary=" +
                encodeURIComponent(location.origin) +
                "&nameProbe" +
                probeNew.columns.name +
                "&type_login=" +
                localStorage.getItem("type_login") +
                "&lang=" +
                (localStorage.getItem("language") ??
                  localStorage.getItem("language_default"));
            } else
              probeNew.url =
                probeNew.url +
                "?" +
                tokenFragment +
                "&pid=" +
                probeNew.id +
                "&urlPrimary=" +
                encodeURIComponent(location.origin) +
                "&nameProbe=" +
                probeNew.columns.name +
                "&type_login=" +
                localStorage.getItem("type_login") +
                "&lang=" +
                (localStorage.getItem("language") ??
                  localStorage.getItem("language_default"));
          }
          url = probeNew.url;

          this.probeData = {
            id: probeNew.id,
            link: url,
          };
        } else {
          this.probeData = {
            id: "",
            link: "",
          };
        }
      } catch (e) {
        console.error("Có lỗi: %o", e);
      }
    },

    onRoutechange(ele) {
      this.initActiveMenu(ele.path);
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition =
          document.getElementsByClassName("mm-active")[0].offsetTop;
        if (currentPosition > 500)
          if (this.$refs.isSimplebar)
            this.$refs.isSimplebar.value.getScrollElement().scrollTop =
              currentPosition + 300;
      }
    },

    initActiveMenu(ele) {
      setTimeout(() => {
        const menuTree = this.listMenu.find(
          (menu) =>
            menu.children.length > 0 &&
            menu.children.map((item) => item.link).includes(ele)
        );

        if (document.querySelector("#navbar-nav")) {
          let a = document
            .querySelector("#navbar-nav")
            .querySelector('[href="#a' + menuTree?.id + '"]');
          if (a) {
            let b = document
              .querySelector("#navbar-nav")
              .querySelector('[href="#a' + menuTree?.id + '"] + div');

            a.classList.add("active");
            if (b) {
              b.classList.add("show");
              b.parentElement.children[0].classList.add("active");
              b.parentElement.children[0].setAttribute("aria-expanded", "true");
              if (b.parentElement.closest(".collapse.menu-dropdown")) {
                b.parentElement.closest(".collapse").classList.add("show");
                if (b.parentElement.closest(".collapse").previousElementSibling)
                  b.parentElement
                    .closest(".collapse")
                    .previousElementSibling.classList.add("active");
              }
            }
          }
        }
      });
    },

    async getMenu() {
      try {
        const response = await mushroom.menu.views.get_treeAsync(
          {
            domains: ["primary", localStorage.getItem("type_login")],
            currentDomain: "primary",
          },
          { clientCache: true }
        );

        this.listMenu = response.result;
        this.initActiveMenu(window.location.pathname);
      } catch (e) {
        console.error("Có lỗi: %o", e);
      }
    },

    setDomainProbe() {
      let listDomain = [];
      const valueDomain = localStorage.getItem("domainProbe");
      if (valueDomain) {
        listDomain = valueDomain.split(",");
      }
      const domain = this.probeData.link.substring(
        0,
        this.probeData.link.indexOf("/entry.html")
      );
      if (listDomain.length === 0 || !listDomain.includes(domain)) {
        listDomain.push(domain);
      }
      localStorage.setItem("domainProbe", listDomain.join(","));
    },

    setLink(link) {
      if (link === "#") return "/";
      return link;
    },

    setLinkPrimary(link) {
      this.setDomainProbe();
      if (link === "#") return this.probeData.link;
      return this.probeData.link + "&menu=" + link.slice(1);
    },

    setIcon(iconId) {
      if (iconId) return MethodService.thumbIcon(iconId, 32);
      return noneIconMenu;
    },

    notiLink() {
      toastr.error(
        LanguageService[this.lang_web.showLang.toLocaleUpperCase()]
          .toastr_not_have_probe_info ?? "toastr_not_have_probe_info"
      );
    },

    probeNotSave(link) {
      this.setDomainProbe();
      localStorage.setItem("probeId", this.probeData.id);
      if (link === "#") location.href = this.probeData.link;
      else location.href = this.probeData.link + "&menu=" + link.slice(1);
    },

    setLinkProbeNotChildren(linkMenu) {
      this.setDomainProbe();

      return this.probeData.link + "&menu=" + linkMenu.slice(1);
    },

    onChangeLang() {
      let langIndexInUrl = this.probeData.link.lastIndexOf("&lang=");
      let newURL =
        this.probeData.link.substring(0, langIndexInUrl) +
        "&lang=" +
        localStorage.getItem("language");
      this.probeData.link = newURL;
    },

    async logViewMenu(id, link, target, children) {
      if (target === "primary") {
        mushroom.menu.log_view_menuAsync({
          body: {
            menu_id: id,
          },
        });
        this.$router.push(link);
      } else {
        try {
          await mushroom.menu.log_view_menuAsync({
            body: {
              menu_id: id,
            },
          });
        } catch (error) {
          console.error("Có lỗi: %o", error);
        }
        window.location.href = children?.length
          ? this.setLinkPrimary(link)
          : this.setLinkProbeNotChildren(link);
      }
    },
  },
};
</script>

<template>
  <div class="container-fluid">
    <ul class="navbar-nav h-100" id="navbar-nav">
      <!-- menu động -->
      <li class="nav-item" v-for="menu in listMenu" :key="menu.id">
        <template v-if="menu.children.length > 0">
          <a
            class="nav-link menu-link"
            :href="`#a${menu.id}`"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarMultilevel"
          >
            <img
              :src="setIcon(menu?.icon_id)"
              alt="icon"
              style="width: 18px; margin-right: 10px"
            />
            <span>{{
              menu?.label_lang?.[this.lang_web.showLang] &&
              menu.label_lang?.[this.lang_web.showLang].trim() !== ""
                ? menu.label_lang?.[this.lang_web.showLang]
                : menu.label
            }}</span>
          </a>
          <div class="collapse menu-dropdown" :id="`a${menu.id}`">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item" v-for="item in menu.children" :key="item.id">
                <template v-if="item.target_domain === 'primary'">
                  <router-link
                    :to="setLink(item.link)"
                    class="nav-link"
                    @click.prevent="
                      logViewMenu(item.id, item.link, item.target_domain)
                    "
                  >
                    <img
                      :src="setIcon(item?.icon_id)"
                      alt="icon"
                      style="width: 18px; margin-right: 10px"
                    />
                    <span>{{
                      item?.label_lang?.[this.lang_web.showLang] &&
                      item.label_lang?.[this.lang_web.showLang].trim() !== ""
                        ? item.label_lang?.[this.lang_web.showLang]
                        : item.label
                    }}</span>
                  </router-link>
                </template>
                <template v-else>
                  <template v-if="this.probeId">
                    <a
                    class="nav-link"
                    :href="setLinkPrimary(item.link)"
                      data-key=""
                      @click.prevent="
                        logViewMenu(
                          item.id,
                          item.link,
                          item.target_domain,
                          menu.children
                        )
                      "
                    >
                      <img
                        :src="setIcon(item?.icon_id)"
                        alt="icon"
                        style="width: 18px; margin-right: 10px"
                      />
                      <span>{{
                        item?.label_lang?.[this.lang_web.showLang] &&
                        item.label_lang?.[this.lang_web.showLang].trim() !== ""
                          ? item.label_lang?.[this.lang_web.showLang]
                          : item.label
                      }}</span>
                    </a>
                  </template>
                  <template v-if="this.probeData.link && !this.probeId">
                    <div
                      class="nav-link"
                      data-key=""
                      @click="probeNotSave(item.link)"
                      style="cursor: pointer"
                    >
                      <img
                        :src="setIcon(item?.icon_id)"
                        alt="icon"
                        style="width: 18px; margin-right: 10px"
                      />
                      <span>{{
                        item?.label_lang?.[this.lang_web.showLang] &&
                        item.label_lang?.[this.lang_web.showLang].trim() !== ""
                          ? item.label_lang?.[this.lang_web.showLang]
                          : item.label
                      }}</span>
                    </div>
                  </template>
                  <template v-if="!this.probeData.link && !this.probeId">
                    <div
                      @click="notiLink()"
                      class="nav-link"
                      style="cursor: pointer"
                    >
                      <img
                        :src="setIcon(item?.icon_id)"
                        alt="icon"
                        style="width: 18px; margin-right: 10px"
                      />
                      <span>{{
                        item?.label_lang?.[this.lang_web.showLang] &&
                        item.label_lang?.[this.lang_web.showLang].trim() !== ""
                          ? item.label_lang?.[this.lang_web.showLang]
                          : item.label
                      }}</span>
                    </div>
                  </template>
                </template>
              </li>
            </ul>
          </div>
        </template>
        <template v-else>
          <template v-if="menu.target_domain === 'primary'">
            <router-link
              :to="menu.link"
              class="nav-link menu-link"
              :data-key="menu.id"
              @click.prevent="
                logViewMenu(menu.id, menu.link, menu.target_domain)
              "
            >
              <img
                :src="setIcon(menu?.icon_id)"
                alt="icon"
                style="width: 18px; margin-right: 10px"
              />
              <span>{{
                menu?.label_lang?.[this.lang_web.showLang] &&
                menu.label_lang?.[this.lang_web.showLang].trim() !== ""
                  ? menu.label_lang?.[this.lang_web.showLang]
                  : menu.label
              }}</span>
            </router-link>
          </template>
          <template v-else>
            <template v-if="this.probeId">
              <a
              class="nav-link menu-link"
              :href="setLinkProbeNotChildren(menu.link)"
                data-key=""
                @click.prevent="
                  logViewMenu(menu.id, menu.link, menu.target_domain)
                "
              >
                <img
                  :src="setIcon(menu?.icon_id)"
                  alt="icon"
                  style="width: 18px; margin-right: 10px"
                />
                <span>{{
                  menu?.label_lang?.[this.lang_web.showLang] &&
                  menu.label_lang?.[this.lang_web.showLang].trim() !== ""
                    ? menu.label_lang?.[this.lang_web.showLang]
                    : menu.label
                }}</span>
              </a>
            </template>
            <template v-if="this.probeData.link && !this.probeId">
              <div
                class="nav-link menu-link"
                data-key=""
                @click="probeNotSave(menu.link)"
                style="cursor: pointer"
              >
                <img
                  :src="setIcon(menu?.icon_id)"
                  alt="icon"
                  style="width: 18px; margin-right: 10px"
                />
                <span>{{ menu.label }}</span>
              </div>
            </template>
            <template v-if="!this.probeData.link && !this.probeId">
              <div @click="notiLink()" class="nav-link" style="cursor: pointer">
                <img
                  :src="setIcon(menu?.icon_id)"
                  alt="icon"
                  style="width: 18px; margin-right: 10px"
                />
                <span>{{ menu.label }}</span>
              </div>
            </template>
          </template>
        </template>
      </li>

      <li class="nav-item" v-if="this.roles.includes('Admin')">
        <router-link
          to="/menu/list-menu"
          class="nav-link menu-link"
          @click.prevent="
            logViewMenu('ConfigMenu', '/menu/list-menu', 'primary')
          "
        >
          <i class="ri-menu-add-line"></i>
          <span>{{ $t("t-config-menu") }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>
<style scoped>
.loading-container {
  height: 200px;
}
li {
  width: 100%;
}
span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
</style>
